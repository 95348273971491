@import 'config'; // Bootstrap config, colors, and fonts
@import 'bootstrap-custom'; // import Twitter Bootstrap framework

body {
  background-color: $dark-blue;
  background: url('/images/login-bg.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.login-form-container {
  width: 320px;
  margin: auto;
  margin-top: 80px;
}

#login-form {
  .form-content {
    padding: 20px;
    background-color: #cccdcd;

    input[type='email'],
    input[type='text'],
    input[type='password'] {
      height: 40px;
      padding-left: 38px;
    }
    .checkbox {
      text-align: center;
    }
    .form-group {
      position: relative;
      &.center {
        text-align: center;
      }
      &.text {
        label {
          font-size: 18px;
          position: absolute;
          left: 14px;
          top: 8px;
        }
      }
      button[type='submit'] {
        margin: auto;
        background-color: $orange;
        padding: 5px 20px;
        border: 0px solid white;
        color: white;
        text-transform: uppercase;
        font-weight: light;
        i {
          margin-right: 10px;
        }
      }
    }
  }
  #brand-box {
    width: 100%;
    padding: 20px;
    text-align: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: $topspot-blue;
    background: rgba(12, 87, 122, 1);
    background: -moz-linear-gradient(
      45deg,
      rgba(12, 87, 122, 1) 0%,
      rgba(12, 97, 136, 1) 20%,
      rgba(21, 123, 171, 1) 56%,
      rgba(25, 149, 207, 1) 100%
    );
    background: -webkit-gradient(
      left bottom,
      right top,
      color-stop(0%, rgba(12, 87, 122, 1)),
      color-stop(20%, rgba(12, 97, 136, 1)),
      color-stop(56%, rgba(21, 123, 171, 1)),
      color-stop(100%, rgba(25, 149, 207, 1))
    );
    background: -webkit-linear-gradient(
      45deg,
      rgba(12, 87, 122, 1) 0%,
      rgba(12, 97, 136, 1) 20%,
      rgba(21, 123, 171, 1) 56%,
      rgba(25, 149, 207, 1) 100%
    );
    background: -o-linear-gradient(
      45deg,
      rgba(12, 87, 122, 1) 0%,
      rgba(12, 97, 136, 1) 20%,
      rgba(21, 123, 171, 1) 56%,
      rgba(25, 149, 207, 1) 100%
    );
    background: -ms-linear-gradient(
      45deg,
      rgba(12, 87, 122, 1) 0%,
      rgba(12, 97, 136, 1) 20%,
      rgba(21, 123, 171, 1) 56%,
      rgba(25, 149, 207, 1) 100%
    );
    background: linear-gradient(
      45deg,
      rgba(12, 87, 122, 1) 0%,
      rgba(12, 97, 136, 1) 20%,
      rgba(21, 123, 171, 1) 56%,
      rgba(25, 149, 207, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0c577a', endColorstr='#1995cf', GradientType=1 );
    img {
      width: 150px;
      margin: auto;
    }
  }
  .forgot {
    background-color: red;
    width: 100%;
    padding: 10px;
    text-align: center;
    display: block;
    color: white;
    font-size: 10pt;
    background-color: #1f2936;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
