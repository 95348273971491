/**
 * Fonts
**/
@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900');
$font-family-sans-serif: 'Lato', sans-serif;

/**
 * Colors
**/
$blue: #46abd9;
$slightly-dark-blue: #1896d0;
$light-blue: #a1defa;
$dark-blue: #10151b;
$orange: #fe7a3c;
$light-gray: #efefef;
$dark-gray: #808080;
$topspot-blue: #0377a5;

$primary-color: $blue;

$brand-primary: darken($primary-color, 6.5%);
$btn-primary-color: #fff;

/**
 * Components
**/
$grid-gutter-width: 20px;
$border-radius-base: 0px;
$navbar-height: 40px;
$navbar-inverse-link-color: $light-blue;
$sidebar-min-width: 64px;
$grid-float-breakpoint: 992px;
